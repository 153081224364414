@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i,900");
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://cdn.linearicons.com/free/1.0.0/svgembedder.min.js");
@import url("//fonts.googleapis.com/css?family=Open+Sans:400,600,700,800,300");

.windowNavigation {
  text-align: left;
  letter-spacing: 0.24px;
  color: #666666;
  opacity: 1;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 12px;
}
.apiUrl {
  text-align: left;
  letter-spacing: 0.48px;
  color: #202223;
  opacity: 1;
  display: inline;
  font-weight: 600;
  font-size: 24px;
}
.apiButton {
  width: 65px;
  height: 24px;
  background: #29b408 0% 0% no-repeat padding-box;
  border: 0.5px solid #239b07;
  border-radius: 3px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 53%;
  padding: 0px;
  position: absolute;
  margin-top: 6px;
}
.runButton {
  width: 143px;
  height: 33px;
  background: #228f08 0% 0% no-repeat padding-box;
  box-shadow: -1px -1px 3px #00000029;
  border: 0.5px solid #1d7b07;
  border-radius: 2px;
  opacity: 1;
  color: #ffffff;
}
.deployButton {
  width: 103px;
  height: 33px;
  background: #228f08 0% 0% no-repeat padding-box;
  box-shadow: -1px -1px 3px #00000029;
  border: 0.5px solid #1d7b07;
  border-radius: 2px;
  opacity: 1;
  color: #ffffff;
}
.inputTabH {
  text-align: left;
  letter-spacing: 0.28px;
  color: #888a8b;
  opacity: 1;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 13px;
}
.emaiButton {
  height: auto;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  background: #e4e4e4 0% 0% no-repeat padding-box;
  border: 0.5px solid #c5c5c5;
  border-radius: 3px;
}
.addNewWindow {
  width: 300px;
  height: 450px;
  background: #e4e4e4 0% 0% no-repeat padding-box;
  border: 1px solid #00000021;
  border-radius: 3px;
  opacity: 1;
  overflow-y: scroll;
}
.addNewWindow2 {
  width: 600px;
  height: 450px;
  background: #e4e4e4 0% 0% no-repeat padding-box;
  border: 1px solid #00000021;
  border-radius: 3px;
  opacity: 1;
  overflow-y: scroll;
}
.addNewApis {
  width: 700px;
  height: 620px;
  background: #e4e4e4 0% 0% no-repeat padding-box;
  border: 1px solid #00000021;
  border-radius: 3px;
  opacity: 1;
  overflow-y: scroll;
}
.addNewApiHeader {
  height: 40px;
  background: #5C944E 0% 0% no-repeat padding-box;
  border: 1px solid #00000021;
  border-radius: 3px 3px 0px 0px;
  opacity: 1;
  padding: 8px;
}
.addNewHeader {
  height: 40px;
  background: #6EC25A 0% 0% no-repeat padding-box;
  border: 1px solid #00000021;
  border-radius: 3px 3px 0px 0px;
  opacity: 1;
  padding: 8px;
}
.fieldHeading {
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
  font-weight: 600;
}
.contentCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dedede;
  border-radius: 3px;
  opacity: 1;
  width: 100%;
  margin: 10px 10px 0px 10px;
}
.TStyles {
  letter-spacing: 0.44px;
  color: #929292;
  opacity: 1;
  margin-bottom: 0rem;
  font-weight: 600;
  font-size: 22px;
}
.textStyles {
  letter-spacing: 0.28px;
  color: #202223;
  opacity: 1;
  margin-bottom: 0rem;
  font-weight: 600;
  font-size: 13px;
}
.descriptionStyles {
  letter-spacing: 0.75px;
  color: #202223;
  opacity: 1;
  margin-bottom: 0rem;
  font-weight: 500;
  font-size: 14px;
}
.functionDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dedede;
  opacity: 1;
  height: 300px;
}
.numericStyles {
  color: #707070;
  padding-right: 8px;
}
.textStyles {
  letter-spacing: 0.32px;
  color: #202223;
  font-weight: 500;
  font-size: 13px;
  padding-left: 5px;
}
.addNewButton {
  letter-spacing: 0.32px;
  color: #228f08;
  opacity: 1;
  font-size: 13px;
  margin-bottom: 0rem;
  cursor: pointer;
}
.addText {
  letter-spacing: 0.32px;
  color: #202223;
  opacity: 1;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0rem;
}
.addDescription {
  letter-spacing: 0.75px;
  color: #202223;
  opacity: 1;
  font-weight: 500;
  font-size: 11px;
  margin-bottom: 0rem;
}
.collapseIconStyles {
  /* height: 28px; */
  /* background-color: white; */
  text-align: right;
  border-radius: 50px;
  /* margin-left: 40px; */
  /* margin-right: 46px; */
  position: absolute;
  bottom: 10px;
  right: 15px;
  left: 32px;
  cursor: pointer;
}
