.site-layout .site-layout-background {
  /* background: #fff; */
  font-family: "Open Sans" !important;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #2f2929;
}

.ant-menu-submenu-title {
  padding-left: 12px !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #2f2929;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #2f2929;
  /* border-left: 7px solid #29b408; */
}
.ant-menu-item:hover  {
  background-color: #29b408 !important;
}
.ant-menu-submenu:hover{
  background-color: #29b408 !important;
}
.ant-menu-item  {
  font-size: 12px !important;
}
.ant-menu-submenu{
  font-size: 12px !important;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #2f2929;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  /* margin-top: -0.1px;
  padding-top: 0.1px; */
  /* background-color: #2f2929; */
  background: #2F2929 0% 0% no-repeat padding-box;
  /* border: 0.5px solid #DBDBDB; */
  border-top: none !important;
  opacity: 1;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: -6px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 32px;
  line-height: 32px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-layout-sider {
  position: relative;

  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #2f2929;
  cursor: pointer;
  transition: all 0.2s;
  width: 140px !important;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  /* margin-left: -3px; */
  /* padding-left: -3px !important; */
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
  margin-left: -7px;
}

.siderStyles {
  flex: 0 0 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
  width: 180px !important;
}
.siderCollapseStyles {
  flex: 0 0 58px !important;
  max-width: 58px !important;
  min-width: 58px !important;
  width: 58px !important;
}
.ant-tabs-top > .ant-tabs-nav:before,
.ant-tabs-bottom > .ant-tabs-nav:before,
.ant-tabs-top > div > .ant-tabs-nav:before,
.ant-tabs-bottom > div > .ant-tabs-nav:before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #c8c6c6;
  content: "";
  opacity: 1;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 12px 0px 12px;
  font-size: 14px;
  background: 0 0;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-card-body {
  padding: 10px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  /*  margin: 0  */
  font-size: 16px;
  line-height: 40px;
}

/* .ant-menu-submenu .ant-menu-submenu-inline
  > .ant-menu-submenu-title {
    padding-left: 5px;
  } */

/* .ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu-title {
    padding-left: 5px;
  } */
  /* .ant-layout {
    background: #fff !important;
  } */

  /* .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
    opacity: 0 !important;
  } */

  .ant-menu-submenu-arrow{
    opacity: 0 !important;
  }